import React from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';
import { addTrailingSlash } from '../utils';

const LocalizedLink = ({ to, intl: { locale }, ...props }) => {
  let path = to;
  if (!/\/blog/.test(to)) {
    path = locale === 'en' ? to : `/${locale}${to}`;
  }

  return <Link {...props} to={addTrailingSlash(path)} />;
};

export default injectIntl(LocalizedLink);
