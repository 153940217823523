const config = {
  macLink: 'https://geo.itunes.apple.com/us/app/evertool/id1396383432?mt=12',
  winLink: '//www.microsoft.com/store/apps/9MZWLDGGGW9J?ocid=badge',
  howToUseLink: [
    {
      link: 'three-steps-to-get-started',
      title: {
        en: 'Three steps to get started',
        'zh-tw': '三步驟開始使用',
        'zh-cn': '三步骤开始使用',
        ja: '始めるための3つのステップ',
      },
      emoji: '👉',
    },
    {
      link: 'use-markdown-in-evernote',
      title: {
        en: 'Use Markdown in Evernote',
        'zh-tw': '在 Evernote 使用 Markdown',
        'zh-cn': '在印象笔记使用 Markdown',
        ja: 'EvernoteでMarkdownを使用する',
      },
      emoji: '✒',
    },
    {
      link: 'beautify-code',
      title: {
        en: 'Beautify code in Evernote',
        'zh-tw': 'Evernote 美化程式碼',
        'zh-cn': '印象笔记美化程式码',
        ja: 'Evernoteでコードを美化する',
      },
      emoji: '💻',
    },
    {
      link: 'resize-images-to-same-width',
      title: {
        en: 'Resize images to the same width',
        'zh-tw': '將圖片縮放至相同大小',
        'zh-cn': '将图片缩放至相同大小',
        ja: '画像を同じ幅にサイズ変更する',
      },
      emoji: '🖼',
    },
    {
      link: 'customized-highlight-color',
      title: {
        en: 'Customized Evernote highlight color',
        'zh-tw': '自訂 Evernote highlight 顏色',
        'zh-cn': '自订印象笔记高亮颜色',
        ja: 'カスタマイズされたEvernoteハイライトカラー',
      },
      emoji: '🖍',
    },
    {
      link: 'excel-numbers-google-srpeadsheet-to-evernote-table',
      title: {
        en: 'Transform Excel to Evernote table',
        'zh-tw': '將 Excel 轉換為 Evernote 表格',
        'zh-cn': '将 Excel 转换为印象笔记表格',
        ja: 'ExcelをEvernoteテーブルに変換',
      },
      emoji: '📃',
    },
    {
      link: 'transform-url-to-url-with-title',
      title: {
        en: 'Url →  Url + Title',
        'zh-tw': '連結 → 連結＋標題',
        'zh-cn': '连结 → 连结＋标题',
        ja: 'リンク → リンク + タイトル',
      },
      emoji: '🔗',
    },
    {
      link: 'add-style',
      title: {
        en: 'Create customized style',
        'zh-tw': '建立客製化樣式',
        'zh-cn': '建立客制化样式',
        ja: 'カスタマイズされたスタイルを作成する',
      },
      emoji: '💅',
    },
  ],
  howToUseAdvancedLink: [
    {
      link: 'markdown-themes',
      title: {
        en: 'Use multiple Markdown themes',
        'zh-tw': '使用多種 Markdown 主題',
        'zh-cn': '使用多种 Markdown 主题',
        ja: '複数のMarkdownテーマを使用する',
      },
      emoji: '➡',
    },
    {
      link: 'auto-get-markdown-link-title',
      title: {
        en: 'Automatically get the Markdown link title',
        'zh-tw': '自動填入 Markdown 連結標題',
        'zh-cn': '自动填入 Markdown 连结标题',
        ja: 'Markdownリンクのタイトルを自動的に取得する',
      },
      emoji: '➡',
    },
  ],
};

export default config;
