import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import Link from '../Link';
import Logo from '../../images/logo.png';
import './index.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBar: false,
    };
  }

  toggleBar = () => {
    const { showBar } = this.state;
    this.setState({ showBar: !showBar });
  }

  render() {
    const { showBar } = this.state;
    return (
      <>
        <nav className="banner has-background-danger center">
          <a className="has-text-white" href="/blog/compatibility-with-evernote-10">Notice: EverTool is not compatible with Evernote 10</a>
        </nav>
        <nav className="navbar header">
          <div className="container header-container">
            <div className="navbar-brand">
              <Link className="navbar-item" to="/">
                <img src={Logo} height="28" alt="EverTool logo" />
                <span className="logo-text">EverTool</span>
              </Link>
              <div
                tabIndex="0"
                role="button"
                className="navbar-burger"
                aria-label="menu"
                aria-expanded="false"
                onClick={this.toggleBar}
                onKeyDown={this.toggleBar}
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </div>
            </div>
            <div id="navbarExampleTransparentExample" className={cx('navbar-menu', { 'is-active': showBar })}>
              <div className="navbar-start">
                <Link to="/" className="navbar-item">
                  <FormattedMessage id="home" />
                </Link>
                <Link to="/blog/" className="navbar-item"><FormattedMessage id="blog" /></Link>
                <Link to="/how-to-use/" className="navbar-item"><FormattedMessage id="howtouse" /></Link>
                <Link to="/faq/" className="navbar-item"><FormattedMessage id="faq" /></Link>
              </div>
              <div className="navbar-end">
                <div className="navbar-item">
                  <div className="field is-grouped">
                    <p className="control">
                      <a href="/" className="locale-link">English</a>
                      /
                      <a href="/zh-cn/" className="locale-link">简中</a>
                      /
                      <a href="/zh-tw/" className="locale-link">繁中</a>
                      /
                      <a href="/ja/" className="locale-link">日本語</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Header;
