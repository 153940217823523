import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from './footer.module.scss';
import { macOrWindows } from '../../utils';
import config from '../../config';

const isMac = macOrWindows() === 'mac';
const downloadLink = isMac ? config.macLink : config.winLink;

const Footer = () => (
  <footer style={{ textAlign: 'center' }} className={cx(styles.footer, 'pt4 mt5')}>
    <ul className={cx(styles.footerList, 'vcenter')}>
      <li>
        <a href={downloadLink} target="_blank" rel="noopener noreferrer"><FormattedMessage id="download" /></a>
      </li>
      <li>
        <a className="mb2" href="mailto:hello.evertool@gmail.com">
          <i className="fas fa-envelope" />
          {' '}
          <FormattedMessage id="contact.us" />
        </a>
      </li>
      <li>
        <div className="twitter-follow">
          <div className="custom-follow-button">
            <a href="https://twitter.com/intent/user?screen_name=evertoolapp" target="_blank" rel="noopener noreferrer" alt="Follow EverTool">
              <i className="btn-icon" />
              <span className="btn-text">Follow @EvertoolApp</span>
            </a>
          </div>
        </div>
      </li>
    </ul>
    <p className={cx('gray mt3 pb4', styles.copyright)}>
      &copy; Copyright. EverTool All Right Reserved
    </p>
  </footer>
);

export default Footer;
