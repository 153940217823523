const en = require('./en.json');
const zhTW = require('./zh-tw.json');
const zhCN = require('./zh-cn.json');
const ja = require('./ja.json');

module.exports = () => (
  {
    en, 'zh-tw': zhTW, 'zh-cn': zhCN, ja,
  }
);
